<template>
  <v-expansion-panels v-model="opened">
    <v-expansion-panel>
      <v-expansion-panel-header class="subtitle-1">
        Tipos
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-switch @change="find" v-model="filters.active" label="Ativos" />
        <v-data-table :items="items" :headers="headers">
          <template v-slot:item.actions="{ item }">
            <table-action-button
              text="Editar"
              @click="edit(item)"
              icon="edit"
            />
            <table-action-button
              text="Editar ordem"
              @click="changeOrder(item.id)"
              icon="low_priority"
            />
            <table-action-button
              v-if="item.active"
              text="Desativar"
              @click="activeOrDeactivate(item, false)"
              icon="delete"
            />
            <table-action-button
              v-else
              text="Ativar"
              @click="activeOrDeactivate(item, true)"
              icon="check"
            />
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <form-type ref="form" @update="updated" />
  </v-expansion-panels>
</template>

<script>
import { findTypes, updateType } from "@/services/oligoelements-service";
import { pagesNames } from "@/router/pages-names";

export default {
  name: "ListTypes",
  components: {
    FormType: () => import("./FormType"),
  },
  data: () => ({
    items: [],
    headers: [
      { text: "Nome", value: "name" },
      {
        text: "Ações",
        value: "actions",
        width: 150,
        sortable: false,
        align: "start",
      },
    ],
    opened: false,
    filters: {
      active: true,
    },
  }),
  computed: {
    isOpened() {
      return this.opened === 0;
    },
  },
  watch: {
    isOpened(value) {
      if (value && this.items.length === 0) this.find();
    },
  },
  methods: {
    async find() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findTypes(this.filters);
        this.items = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async activeOrDeactivate(item, option) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = option;
        await updateType(item);
        const index = this.findIndexItems(item);
        this.items.splice(index, 1);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    findIndexItems(itemSelected) {
      return this.items.findIndex((item) => item.id === itemSelected.id);
    },
    edit(item) {
      this.$refs.form.edit(item);
    },
    updated(item) {
      console.log(item);
    },
    changeOrder(typeId) {
      this.$router.push({
        name: pagesNames.OLIGOELEMENTS_ORDER_BY_TYPE,
        params: { typeId },
      });
    },
  },
};
</script>

<style scoped></style>
